const cowllector = '0x98360BBa76636CDF014A1F89c764B259b32eD667';
const zeroAddress = '0x0000000000000000000000000000000000000000';
const treasuryMultisig = '0x7c238e61353Cb4098B51fec4f8032662E1481d22';

export const beefyfinance = {
  devMultisig: '0x98360BBa76636CDF014A1F89c764B259b32eD667',
  treasuryMultisig,
  strategyOwner: '0x98360BBa76636CDF014A1F89c764B259b32eD667',
  vaultOwner: '0x98360BBa76636CDF014A1F89c764B259b32eD667',
  keeper: '0x98360BBa76636CDF014A1F89c764B259b32eD667',
  rewarder: cowllector,
  treasurer: treasuryMultisig,
  launchpoolOwner: cowllector,
  rewardPool: '0xA85013f7c3421115514FA57A18711EE979fBb78C',
  treasury: treasuryMultisig,
  beefyFeeRecipient: '0x594cf73607f973d69489B4173AD589eBF435ca98', //KUFIBATCHV2
  multicall: '0xE82B1DCD314B87fB7932d587a2a91F59E117859b',
  bifiMaxiStrategy: zeroAddress,
};
