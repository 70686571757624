import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import Button from 'components/CustomButtons/Button';
import { styles } from '../stake/sections/styles/view';
import Table from './Table';
import { useQuery } from 'react-query';
import { useConnectWallet } from '../home/redux/hooks';

const NFTAvatar = ({ secondPart, userRank }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const rank = userRank?.data?.rank || '';

  return (
    <div
      style={{
        display: 'grid',
        gridAutoRows: 'auto 1fr',
        padding: '20px 16px 32px 0px',
        width: '100%',
      }}
    >
      {!secondPart && (
        <div className="wrap-col">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              paddingRight: 16,
            }}
          >
            <div style={{ minHeight: 80, minWidth: 80, marginRight: 6 }}>
              <img alt="" src="/kusfox-av.png" style={{ height: 80, width: 80 }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h1" className={classes.nftTitle}>
                KuStack NFT Leaderboard
              </Typography>
              <Typography className={classes.nftDesc}>
                Win a KUSFox NFT by staking KUS-KCS LP
              </Typography>
            </div>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              paddingRight: 16,
            }}
          >
            <div style={{ minHeight: 80, minWidth: 80, marginRight: 6 }}></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h2" className={classes.nftTitleV2}>
                Your Rank
              </Typography>
              <Typography className={classes.nftTitleV2}>
                {' '}
                <svg
                  width={22}
                  height={32}
                  viewBox="0 -3 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    height: '28px',
                    color: '#ffbf43',
                  }}
                >
                  <path
                    d="M8.87175 10.9919C8.5912 11.019 8.30389 11.0326 8.01294 11.0326C7.72197 11.0326 7.43466 11.019 7.15414 10.9919C7.07835 10.9846 7.01076 11.0195 7.01076 11.0669V12.0252H6.99696C6.44349 12.0252 5.99478 12.4739 5.99478 13.0273H10.0035C10.0035 12.4786 9.56209 12.0341 9.01513 12.0265V11.0669C9.01513 11.0195 8.94754 10.9846 8.87175 10.9919Z"
                    fill="#ffbf43"
                  />
                  <path
                    d="M11.0057 14.0556H4.99258C4.71583 14.0556 4.49149 14.28 4.49149 14.5567V15.5589C4.49149 15.8357 4.71583 16.06 4.99258 16.06H11.0057C11.2824 16.06 11.5068 15.8357 11.5068 15.5589V14.5567C11.5068 14.28 11.2824 14.0556 11.0057 14.0556Z"
                    fill="#ffbf43"
                  />
                  <path
                    d="M14.7391 0.263154C13.885 -0.165359 12.9434 0.00349358 12.2875 0.536639H3.72731C3.07057 -0.0121004 2.11714 -0.191511 1.25262 0.242268C-0.258041 1.00729 -0.43859 3.07831 0.909165 4.08902L3.53537 6.05488C3.78775 8.30645 5.69586 10.0574 8.01484 10.0574C10.3368 10.0574 12.2472 8.30208 12.4956 6.04631L15.0825 4.10991C16.4309 3.09863 16.2491 1.0278 14.7391 0.263154ZM3.48249 4.70199L1.54326 3.24361C0.814794 2.7056 0.921983 1.59672 1.72821 1.18811C2.53844 0.78285 3.48249 1.37249 3.48249 2.27131V4.70199ZM14.4484 3.26447L12.5247 4.71122V2.14515C12.6175 1.35286 13.4811 0.817861 14.2635 1.20897C15.0708 1.61819 15.176 2.72716 14.4484 3.26447Z"
                    fill="#ffbf43"
                  />
                </svg>{' '}
                {rank ? `#${rank}` : 'N/A'}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {secondPart && (
        <div
          style={{
            margin: '2rem 0px 9rem 0px',
            overflow: 'hidden',
          }}
        >
          <Typography variant="h1" className={classes.nftTitle}>
            Sneak Peak
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px 16px',
              maxWidth: 'calc(1280px + 324px)',
              gap: '1em',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              position: 'relative',
              WebkitBoxAlign: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                position: 'relative',
                alignItems: 'flex-start',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  padding: 0,
                  overflow: 'hidden',
                }}
              >
                {['4', '1', '3', '2', '5'].map(id => (
                  <div
                    key={id}
                    style={{
                      display: 'flex',
                      position: 'relative',
                      flex: '1 1 0%',
                      minWidth: 264,
                      maxWidth: 264,
                      height: 264,
                      width: 264,
                      borderRadius: 24,
                      overflow: 'hidden',
                      backgroundColor: 'hsl(240deg 20% 17%)',
                    }}
                  >
                    <img alt="" src={`/preview (${id}).png`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function LeaderBoard() {
  const useStyles = makeStyles(styles);
  const { chain } = useParams();
  const classes = useStyles();
  const { address: account } = useConnectWallet();
  const { isLoading, error, data } = useQuery('leaderboard', () =>
    fetch('https://strms.kuswap.finance/api/kustack/leaderboard').then(res => res.json())
  );
  const {
    isLoading: userRankLoading,
    error: userRankError,
    data: userRankData,
  } = useQuery('user-rank', () =>
    fetch(`https://strms.kuswap.finance/api/kustack/rank?address=${account}`).then(res =>
      res.json()
    )
  );

  return (
    <>
      <Helmet>
        <title>KuStack NFT Leaderboard</title>
        <meta property="og:title" content="KuStack NFT Leaderboard" />
      </Helmet>
      <Grid container>
        <Grid item xs={6} className={classes.mb}>
          <Link to={`/${chain}/stake`}>
            <Button className={classes.roundedBtn}>Back</Button>
          </Link>
        </Grid>
        <Grid item xs={6} className={classes.mb}>
          <Typography variant="h2" className={classes.countdown}>
            KuStack NFT Leaderboard
          </Typography>
        </Grid>{' '}
        <NFTAvatar secondPart={false} userRank={userRankData && userRankData} />
      </Grid>
      <Grid container>
        <Table leaderboard={data} isLoading={isLoading || userRankLoading} />
      </Grid>
      <NFTAvatar secondPart={true} userRank={userRankData || {}} />
    </>
  );
}
