import { addressBook } from 'address-book';

const { kcc: kccAAddressBook } = addressBook;
export { kccAAddressBook };

export {
  vaultABI,
  bnbVaultABI,
  erc20ABI,
  strategyABI,
  multicallABI,
  govPoolABI,
  beefyUniV2ZapABI,
  uniswapV2PairABI,
  uniswapV2RouterABI,
  launchPoolABI,
} from './abi';
export { bscStakePools } from './stake/bsc_stake';
export { bscPools } from './vault/bsc_pools';
export { bscZaps } from './zap/bsc_zaps';
// kcc
export { kccStakePools } from './stake/kcc_stake';
export { kccPools } from './vault/kcc_pools';
export { kccZaps } from './zap/kcc_zaps';
export { nativeCoins } from './native_coins';
