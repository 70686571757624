const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxWidth: '40rem',
    margin: '0rem auto',
    '@media (min-width: 769px)': {
      margin: '0rem auto 10rem',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 586px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '10px 5rem',
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 900,
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  p: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  link: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  kusgov: {
    '&:hover': {
      color: 'lightgreen',
    },
  },
  kcs: {
    '&:hover': {
      color: 'rgb(45, 189, 150)',
    },
  },
  linkTelegram: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: '#5EB5F7',
    },
  },
  linkVote: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: '#F3B04E',
    },
  },
  linkTwitter: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: 'rgb(29, 155, 240)',
    },
  },
  linkDiscord: {
    margin: '0.5rem 0',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: 'rgb(71, 82, 196)',
    },
  },
  linkIcon: {
    marginRight: '0.5rem',
    minWidth: '24px',
  },
});

export default styles;
