const KCS = {
  name: 'Kucoin Token',
  address: '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
  symbol: 'WKCS',
  decimals: 18,
  chainId: 321,
  website: 'https://www.kcc.io/',
  description:
    'KCC IS SOLVING ETHEREUMS SIX BIGGEST CHALLENGES. SIMPLICITY. SPEED. STORAGE. SCALABILITY. SECURITY. SAVINGS.',
  logoURI: 'https://kuswap.finance/tokens/kcs.png',
};

const _tokens = {
  KCS,
  WKCS: KCS,
  WNATIVE: KCS,
  BIFI: {
    name: 'KuStack',
    symbol: 'KUS',
    address: '0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14',
    chainId: 321,
    decimals: 18,
    website: 'https://KuStack.finance/',
    description:
      'KuStack Finance is a Decentralized, KCC Yield Optimizer platform that allows its users to earn compound interest on their crypto holdings.',
    logoURI: 'https://kuswap.finance/kus.png',
  },
  KUFI: {
    name: 'KuSwap.Finance',
    symbol: 'KUS',
    address: '0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14',
    chainId: 321,
    decimals: 18,
    website: 'https://KuStack.finance/',
    description:
      'KuSwap Finance is a Decentralized, KCC Yield Optimizer platform that allows its users to earn compound interest on their crypto holdings.',
    logoURI: 'https://kuswap.finance/0xF5A9347f574067F841c811AA83d3fa1f121A7.png',
  },
  WBTC: {
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    address: '0xfa93c12cd345c658bc4644d1d4e1b9615952258c',
    chainId: 321,
    decimals: 8,
    website: 'https://wbtc.network/',
    description:
      'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.',
    logoURI: 'https://ftmscan.com/token/images/wBTC_32.png',
  },
  KUS: {
    name: 'KuSwap Token',
    symbol: 'KUS',
    address: '0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14',
    chainId: 321,
    decimals: 18,
    logoURI: 'https://kuswap.finance/kus.png',
    website: 'https://kuswap.finance/#/swap',
    description: 'KuSwap is the #1 decentralized exchange (DEX) on KCC',
  },
  WETH: {
    name: 'Ether',
    symbol: 'WETH',
    address: '0xf55af137a98607f7ed2efefa4cd2dfe70e4253b1',
    chainId: 321,
    decimals: 18,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0x420000000000000000000000000000000000000A.svg',
    website: 'https://ethereum.org/',
    description:
      'The native currency that flows within the Ethereum economy is called Ether (ETH). Ether is typically used to pay for transaction fees called Gas, and it is the base currency of the network.',
  },
  USDT: {
    name: 'USDT Token',
    symbol: 'USDT',
    address: '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
    chainId: 321,
    decimals: 6,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC.svg',
    website: 'https://tether.to/',
    description:
      'Tether is a stablecoin pegged to the US Dollar. A stablecoin is a type of cryptocurrency whose value is pegged to another fiat currency like the US Dollar or to a commodity like Gold.Tether is the first stablecoin to be created and it is the most popular stablecoin used in the ecosystem.',
  },
  USDC: {
    name: 'USDC Token',
    symbol: 'USDC',
    address: '0x980a5afef3d17ad98635f6c5aebcbaeded3c3430',
    chainId: 321,
    decimals: 6,
    logoURI:
      'https://pancakeswap.finance/images/tokens/0xEA32A96608495e54156Ae48931A7c20f0dcc1a21.svg',
    website: 'https://www.circle.com/usdc',
    description:
      'USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
  },
};

export const tokens = _tokens;
