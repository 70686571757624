import { connectors } from 'web3modal';
import { indexBy } from './utils';
import { kccAddressBook } from 'address-book';
import { DeFiConnector } from 'deficonnect';
import WalletLink from 'walletlink';
import { CloverConnector } from '@clover-network/clover-connector';
import { allNetworks } from '../../network';
import { kccStakePools, kccPools, kccZaps } from '../configure';
import { nativeCoins } from '../configure/native_coins';
import WalletConnectProvider from '@walletconnect/web3-provider';
export const appNetworkId = window.REACT_APP_NETWORK_ID;

const networkTxUrls = {
  56: hash => `https://bscscan.com/tx/${hash}`,
  312: hash => `https://scan.kcc.io/tx/${hash}`,
};

const networkFriendlyName = {
  56: 'BSC',
  321: 'KCC',
};

const networkBuyUrls = {
  56: 'https://app.1inch.io/#/r/0xF4cb25a1FF50E319c267b3E51CBeC2699FB2A43B',
  321: 'https://kuswap.finance/#/swap?outputCurrency=0xe6801928061cdbe32ac5ad0634427e140efd05f9',
};

export const getNetworkCoin = () => {
  return nativeCoins.find(coin => coin.chainId === appNetworkId);
};

export const getNetworkPools = () => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 321:
      return kccPools;
    default:
      return [];
  }
};

export const getNetworkVaults = (networkId = appNetworkId) => {
  switch (networkId) {
    case 321:
      return indexBy(kccPools, 'id');
    default:
      return {};
  }
};

export const getNetworkLaunchpools = (networkId = appNetworkId) => {
  switch (networkId) {
    case 321:
      return indexBy(kccStakePools, 'id');
    default:
      return {};
  }
};

export const getNetworkTokens = () => {
  const chainId = window.REACT_APP_NETWORK_ID;
  switch (chainId) {
    case 321:
      return kccAddressBook.tokens;
    default:
      throw new Error(`Create address book for chainId(${chainId}) first`);
  }
};

export const getNetworkBurnTokens = () => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 321:
      return {};
    default:
      throw new Error(`Create address book for this chainId first.`);
  }
};

export const getNetworkZaps = () => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 321:
      return kccZaps;
    default:
      return [];
  }
};

export const getNetworkStables = () => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 321:
      return ['BUSD', 'USDT', 'USDC', 'DAI'];
    default:
      return [];
  }
};

export const getNetworkMulticall = () => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 321:
      return '0xE82B1DCD314B87fB7932d587a2a91F59E117859b';
    default:
      return '';
  }
};

export const getNetworkConnectors = t => {
  switch (window.REACT_APP_NETWORK_ID) {
    case 56:
      return {
        network: 'binance',
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: 'MetaMask',
            },
          },
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: 'https://bsc-dataseed.binance.org/',
                56: 'https://bsc-dataseed.binance.org/',
              },
            },
          },
          'custom-clover-bsc': {
            display: {
              logo: require(`images/wallets/clover.png`),
              name: 'Clover Wallet',
              description: t('Connect with your Clover wallet and earn CLV'),
            },
            options: {
              supportedChainIds: [56],
            },
            package: CloverConnector,
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);
              await provider.activate();
              return provider.getProvider();
            },
          },
          'custom-binance': {
            display: {
              name: 'Binance',
              description: t('Binance Chain Wallet'),
              logo: require(`images/wallets/binance-wallet.png`),
            },
            package: 'binance',
            connector: async (ProviderPackage, options) => {
              const provider = window.BinanceChain;
              await provider.enable();
              return provider;
            },
          },
          'custom-math': {
            display: {
              name: 'Math',
              description: t('Math Wallet'),
              logo: require(`images/wallets/math-wallet.svg`),
            },
            package: 'math',
            connector: connectors.injected,
          },
          'custom-twt': {
            display: {
              name: 'Trust',
              description: t('Trust Wallet'),
              logo: require(`images/wallets/trust-wallet.svg`),
            },
            package: 'twt',
            connector: connectors.injected,
          },
          'custom-safepal': {
            display: {
              name: 'SafePal',
              description: t('SafePal App'),
              logo: require(`images/wallets/safepal-wallet.svg`),
            },
            package: 'safepal',
            connector: connectors.injected,
          },
          'custom-cb-bsc': {
            display: {
              logo: require(`images/wallets/coinbase.png`),
              name: 'Coinbase Wallet',
              description: t('Connect to your Coinbase Wallet'),
            },
            options: {
              appName: 'KuStack Finance',
              appLogoUrl: 'https://app.kustack.finance/static/media/BIFI.e797b2e4.png',
              darkMode: false,
            },
            package: WalletLink,
            connector: async (ProviderPackage, options) => {
              const walletLink = new ProviderPackage(options);

              const provider = walletLink.makeWeb3Provider('https://bsc-dataseed.binance.org/', 56);

              await provider.enable();

              return provider;
            },
          },
        },
      };
    case 25:
      return {
        network: 'cronos',
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: 'MetaMask',
            },
          },
          'custom-cdc': {
            display: {
              logo: require(`images/wallets/crypto.png`),
              name: 'Crypto.com',
              description: t('Crypto.com | Wallet Extension'),
            },
            options: {
              supportedChainIds: [25],
              rpc: {
                25: 'https://evm-cronos.crypto.org/', // cronos mainet
              },
              pollingInterval: 15000,
            },
            package: DeFiConnector,
            connector: async (packageConnector, options) => {
              const connector = new packageConnector({
                name: 'Cronos',
                supprtedChainTypes: ['eth'],
                supportedChainTypes: ['eth'],
                eth: options,
                cosmos: null,
              });
              await connector.activate();

              return connector.getProvider();
            },
          },
          'custom-wc-cronos': {
            display: {
              logo: require(`images/wallets/wallet-connect.svg`),
              name: 'Wallet Connect',
              description: t('Scan your WalletConnect to Connect'),
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: 'https://evm-cronos.crypto.org/',
                25: 'https://evm-cronos.crypto.org/',
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);

              await provider.enable();

              return provider;
            },
          },
        },
      };
    case 321:
      return {
        network: 'kcc',
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: 'MetaMask',
            },
          },
          'custom-wc-kcc': {
            display: {
              logo: require(`images/wallets/wallet-connect.svg`),
              name: 'Wallet Connect',
              description: t('Scan your WalletConnect to Connect'),
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                1: 'https://rpc-mainnet.kcc.network',
                321: 'https://rpc-mainnet.kcc.network',
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);
              await provider.enable();
              return provider;
            },
          },
        },
      };
    default:
      return {};
  }
};

export const getNetworkTxUrl = networkTxUrls[window.REACT_APP_NETWORK_ID];
export const getNetworkFriendlyName = (networkId = window.REACT_APP_NETWORK_ID) =>
  networkFriendlyName[networkId];
export const getNetworkBuyUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  networkBuyUrls[networkId];
export const getNetworkAppUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname +
  '#' +
  allNetworks.find(n => n.id === networkId)?.hash;

export const launchpools = getNetworkLaunchpools();
export const vaults = getNetworkVaults();
