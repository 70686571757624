import * as React from 'react';
import { styles } from './styles';
import { Avatar, makeStyles } from '@material-ui/core';
import { getSingleAssetSrc } from '../../../helpers/getSingleAssetSrc';
import { AvatarGroup } from '@material-ui/lab';

let validAssets = ['WKCS-KUS LP', 'KUS', 'KCS', 'WKCS', 'KUS+WKCS'];

const useStyles = makeStyles(styles);

export const AssetsImage = ({ img = '', alt }) => {
  const classes = useStyles();
  const symbol = img;
  // check if img is validAsset
  const isValid = validAssets.includes(symbol);

  let assets = '';

  const hasPlus = symbol.includes('+');
  const isMultiAsset = symbol.indexOf(' ') > -1;
  if (hasPlus) {
    assets = symbol.split('+');
  }
  if (isMultiAsset) {
    const symbolNoSpace = symbol.replace(/\s/g, '').replace('LP', '');
    assets = symbolNoSpace.split('-');
  }

  return (
    <>
      {!img && !isMultiAsset && !hasPlus && <></>}
      {img && !isMultiAsset && isValid && !hasPlus && (
        <Avatar
          className={classes.large}
          alt={alt}
          src={getSingleAssetSrc(img)}
          variant="square"
          imgProps={{ style: { objectFit: 'contain', width: 28, height: 28 } }}
        />
      )}
      {isMultiAsset && isValid && (
        <>
          <AvatarGroup
            className={`${classes.icon} MuiAvatar-root MuiAvatar-square`}
            spacing="small"
          >
            <Avatar
              alt={''}
              variant="square"
              imgProps={{
                style: { objectFit: 'contain', width: 18, height: 18, marginRight: '-10px' },
              }}
              src={getSingleAssetSrc(assets[0])}
            />
            <Avatar
              alt={''}
              variant="square"
              imgProps={{ style: { objectFit: 'contain', width: 16, height: 16 } }}
              src={getSingleAssetSrc(assets[1])}
            />
          </AvatarGroup>
        </>
      )}
      {hasPlus && isValid && (
        <>
          <AvatarGroup
            className={`${classes.icon} MuiAvatar-root MuiAvatar-square`}
            spacing="small"
          >
            <Avatar
              alt={''}
              variant="square"
              imgProps={{
                style: { objectFit: 'contain', width: 18, height: 18, marginRight: '-10px' },
              }}
              src={getSingleAssetSrc(assets[0])}
            />
            <Avatar
              alt={''}
              variant="square"
              imgProps={{ style: { objectFit: 'contain', width: 16, height: 16 } }}
              src={getSingleAssetSrc(assets[1])}
            />
          </AvatarGroup>
        </>
      )}
    </>
  );
};
