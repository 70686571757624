import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    bg: {
      backgroundSize: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: -1,
      left: 0,
    },
    bgElement: {},
    bgLight1: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      backgroundImage: `url('/art/orbits1.svg')`,
      transform: 'rotate(180deg)',
    },
    bgLight2: {
      backgroundPosition: 'left -280px top 40%',
      backgroundImage: `url('/art/orbits2.svg')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '560px 560px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundPosition: 'left -280px top 60%',
        backgroundImage: `url('/art/orbits2.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '560px 560px',
        transform: 'rotate(180deg)',
      },
    },
    bgLight3: {
      backgroundPosition: 'right -156px top 70%',
      backgroundImage: `url('/art/orbits4.svg')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '560px 560px',
      transform: 'rotate(180deg)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundPosition: 'right -156px top 30%',
        backgroundImage: `url('/art/orbits4.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '560px 560px',
        transform: 'rotate(180deg)',
      },
    },
    bgLight4: {
      [theme.breakpoints.up('md')]: {
        backgroundPosition: '800px center',
        backgroundImage: `url('/art/orbits5.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '822px 822px',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(20%)',
          backgroundPositionY: 'bottom',
          backgroundImage: `url('/art/orbits6.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '232px 232px',
        },
      },
    },
    bgLight5: {
      [theme.breakpoints.up('md')]: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top -230px center',
        backgroundImage: `url('/art/orbits1.svg')`,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(0%)',
          backgroundPositionY: 'calc(35%)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '320px 320px',
        },
      },
    },
    bgDark1: {
      backgroundImage: `url('/art/stars.svg')`,
      backgroundRepeat: 'repeat',
    },
    bgDark2: {
      backgroundImage: `url('/art/stars.svg')`,
      backgroundRepeat: 'repeat',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundPosition: 'right -200px top 60%',
        backgroundImage: `url('/art/orbits3.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '660px 610px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundPosition: 'right -200px top 60%',
        backgroundImage: `url('/art/orbits3.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '660px 610px',
        transform: 'rotate(180deg)',
      },
    },
    bgDark3: {
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url('/art/stars.svg')`,
        backgroundRepeat: 'repeat',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPosition: '800px center',
          backgroundImage: `url('/art/orbits7.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '822px 822px',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(20%)',
          backgroundPositionY: 'bottom',
          backgroundImage: `url('/art/orbits8.svg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '232px 232px',
        },
      },
    },
    bgDark4: {
      [theme.breakpoints.up('xs')]: {
        backgroundImage: `url('/art/stars.svg')`,
        backgroundRepeat: 'repeat',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundImage: `url('/art/orbits11.svg')`,
          backgroundSize: '822px 822px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top -430px center',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(0%)',
          backgroundPositionY: 'calc(35%)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '320px 320px',
        },
        '& $bgElement::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(0%)',
          backgroundPositionY: 'calc(40%)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '320px 320px',
          transform: 'rotate(180deg)',
        },
        '& $bgElement': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundPositionX: 'calc(0%)',
          backgroundPositionY: 'calc(85%)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '320px 320px',
        },
      },
    },
  })
);

// LIGHT
// 1 - orbits on the top
// 2 - orbits on the sides (transfer)
// 3 - orbits on the sides type 2
// 4 - other orbits
// 5 - long landing with orbits on the sides
//
// DARK
// 1 - stars
// 2 - orbits on the sides + stars
// 3 - other orbits + stars
// 4 - long landing with orbits on the sides + stars

export const ArtBackground = props => {
  const styles = useStyles();
  const theme = useTheme();
  const { lightKind, darkKind, className } = props;
  const lightKindClass = `bgLight${lightKind}`;
  const darkKindClass = `bgDark${darkKind}`;
  const isLight = theme.palette.type === 'light';
  return (
    <div
      className={clsx(
        styles.bg,
        {
          [styles[lightKindClass]]: isLight,
          [styles[darkKindClass]]: !isLight,
        },
        className
      )}
    >
      <div className={styles.bgElement} />
    </div>
  );
};
