import { enqueueSnackbar } from '../common/redux/actions';
const ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kustackVault',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenAmountOutMin',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenInAmount',
        type: 'uint256',
      },
    ],
    name: 'kuIn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kustackVault',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenAmountOutMin',
        type: 'uint256',
      },
    ],
    name: 'kuInETH',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kustackVault',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'withdrawAmount',
        type: 'uint256',
      },
    ],
    name: 'kuOut',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kustackVault',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'withdrawAmount',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'desiredToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'desiredTokenOutMin',
        type: 'uint256',
      },
    ],
    name: 'kuOutAndSwap',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_router',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_WETH',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    stateMutability: 'payable',
    type: 'receive',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'kustackVault',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenIn',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'fullInvestmentIn',
        type: 'uint256',
      },
    ],
    name: 'estimateSwap',
    outputs: [
      {
        internalType: 'uint256',
        name: 'swapAmountIn',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'swapAmountOut',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'swapTokenOut',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_pair',
        type: 'address',
      },
    ],
    name: 'getPairSwapFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'minimumAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'router',
    outputs: [
      {
        internalType: 'contract IUniswapV2Router02',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const zapWithdraw = async ({
  web3,
  address,
  vaultAddress,
  amount,
  zapAddress,
  dispatch,
}) => {
  console.log('kuOut(vaultAddress, amount)', vaultAddress, amount);

  const contract = new web3.eth.Contract(ABI, zapAddress);
  const transaction = contract.methods.kuOut(vaultAddress, amount).send({
    from: address,
  });

  return promisifyTransaction(transaction, dispatch);
};

export const zapWithdrawAndSwap = async ({
  web3,
  address,
  vaultAddress,
  amount,
  zapAddress,
  tokenOut,
  amountOutMin,
  dispatch,
}) => {
  console.log(
    'kuOutAndSwap(vaultAddress, amount, tokenOut, amountOutMin)',
    vaultAddress,
    amount,
    tokenOut,
    amountOutMin
  );

  const contract = new web3.eth.Contract(ABI, zapAddress);
  const transaction = contract.methods
    .kuOutAndSwap(vaultAddress, amount, tokenOut, amountOutMin)
    .send({
      from: address,
    });
  return promisifyTransaction(transaction, dispatch);
};

const promisifyTransaction = (transaction, dispatch) => {
  return new Promise((resolve, reject) => {
    transaction
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};
