export const nativeCoins = [
  {
    chainId: 321,
    name: 'KuCoin Token',
    symbol: 'KCS',
    decimals: 18,
    wrappedSymbol: 'WKCS',
    allowance: Infinity,
  },
];
