import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

const columns = [
  { id: 'rank', label: 'Rank', minWidth: 10, align: 'left' },
  { id: 'address', label: 'Player', minWidth: 100, align: 'left' },
  { id: 'lp', label: 'Score', minWidth: 100, align: 'center' },
];

function createData(rank, address, lp) {
  // address = 0x3a9c5e0c405b039eeb6f2f7ccca5bfccb2bc69a3
  // short address to string....string
  const shortAddress =
    address.substring(0, 6) + '...' + address.substring(address.length - 6, address.length);
  // lp = 100.2
  // each 10 LP is 1 score without decimals
  const score = Math.floor(lp / 10);
  return { rank, address: shortAddress, lp: score };
}
// {
//     "rank": 1,
//     "address": "0xef6d79883d33b7512a7118a80547d8f907d4779b",
//     "lp": 11032.711274656886
// }

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
  root: {
    width: '100%',
    border: 'none',
    // '&:nth-of-type(odd)': {
    //   backgroundColor: 'white',
    // },
    // '&:nth-of-type(even)': {
    //   backgroundColor: 'grey',
    // },
  },
});

export default function StickyHeadTable({ leaderboard, isLoading }) {
  const rows = leaderboard
    ? leaderboard.data.slice(0, 100).map(({ rank, address, lp }, index) => {
        return createData(
          rank,
          address,
          lp
          // rank,
          // address,
          // lp,
        );
      })
    : [];

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ border: 'none' }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={rows.length}
              page={page}
              onPageChange={handleChangePage}
              labelRowsPerPage=""
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Skeleton height="400px" width="100%" />
      )}
    </>
  );
}
