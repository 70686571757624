import { kcc } from './kcc';

const _addressBook = kcc;

const _addressBookByChainId = {
  321: kcc,
};

export const addressBook = _addressBook;
export const kccAddressBook = _addressBook;
export const addressBookByChainId = _addressBookByChainId;
