import { govPoolABI } from '../abi';

export const kccStakePools = [
  {
    id: 'kuswap-kcs-kus',
    name: 'KUSGOV',
    logo: 'stake/kuswap/KUSGOV.png',
    token: 'KUSGOV',
    tokenDecimals: 18,
    tokenAddress: '0xe8Aa65434A734186F607C1E95efb11B31Dbe1757',
    tokenOracle: 'tokens',
    tokenOracleId: 'KUSGOV',
    earnedToken: 'WKCS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
    earnContractAddress: '0xA85013f7c3421115514FA57A18711EE979fBb78C',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WKCS',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    partners: [
      {
        logo: 'stake/kuswap/KUSGOV.png',
        logoNight: 'stake/kuswap/KUSGOV.png',
        background: 'stake/kuswap/background.png',
        text: "KuStack Finance is Yield Optimizer, enabling users to earn autocompounded yield on their crypto. For this pool, KCS dividends are gathered and sent proportionally to each staker. Stake here, return later to claim the KCS you've earned.",
        website: '',
        social: {},
      },
    ],
  },
];
