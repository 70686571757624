import { beefyfinance } from './platforms/beefyfinance';
import { kuswap } from './platforms/kuswap';
import { tokens } from './tokens/tokens';

export function convertSymbolTokenMapToAddressTokenMap(symbolTokenMap: T) {
  return Object.fromEntries(Object.values(symbolTokenMap).map(t => [t.address, t]));
}

export const kcc = {
  platforms: {
    beefyfinance,
    kuswap,
  },
  tokens,
  tokenAddressMap: convertSymbolTokenMapToAddressTokenMap(tokens),
};
