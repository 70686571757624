import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Box, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import Brightness7 from '@material-ui/icons/Brightness7';
import Brightness2 from '@material-ui/icons/Brightness2';
import { getNetworkBuyUrl } from '../../features/helpers/getNetworkData';
import { Dialog, withStyles } from '@material-ui/core';
import CustomButton from '../../components/CustomButtons/Button';
import Transak from '../Transak/Transak';
import styles from './styles';

const useStyles = makeStyles(styles);

const StyledDialog = withStyles(theme => ({
  paper: {
    margin: '16px',
    backgroundColor: theme.palette.background.primary,
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 32px)',
  },
}))(Dialog);

const Header = ({ links, isNightMode, setNightMode }) => {
  const { chain } = useParams();

  const location = useLocation();
  const isStake = location?.pathname === '/kcc/stake';
  const isHome = location?.pathname === '/kcc';
  const isLeaderboard = location?.pathname === '/leaderboard';
  const NavLinks = () => {
    const classes = useStyles();
    const navLinks = [
      { title: 'Trade Crypto', path: 'https://kuswap.finance' },
      { title: 'KUS Lottery', path: 'https://play.kuswap.finance' },
    ];

    return (
      <>
        <NavLink
          activeClassName={isHome ? classes.active : undefined}
          className={classes.navLink}
          key={'vault'}
          to="/kcc"
        >
          All Vaults
        </NavLink>
        <NavLink
          activeClassName={isStake ? classes.active : undefined}
          className={classes.navLink}
          key={'stake'}
          to="/kcc/stake"
        >
          Stake KUSGov
        </NavLink>
        <NavLink
          activeClassName={isLeaderboard ? classes.active : undefined}
          className={classes.navLink}
          key={'leaderboard'}
          to="/kcc/leaderboard"
        >
          Leaderboard
        </NavLink>
        {/* <NavLink activeClassName={classes.active} className={classes.navLink} key={'nfts'} to="/nfts">
        {t('Header-Nfts')}
      </NavLink> */}
        {navLinks.map(({ title, path }) => (
          <Typography key={title} variant="body1" className={classes.navLink}>
            <a target="_blank" rel="noreferrer" href={path} key={title}>
              {title}
            </a>
          </Typography>
        ))}
      </>
    );
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar className={`${classes.appBar} ${classes.dark}`} position="relative">
      <Toolbar className={classes.container}>
        <Link to={`/${chain}`}>
          <Button className={classes.title}>
            <Hidden xsDown>
              <span className="kuswap-font">KUSTACK</span>
            </Hidden>
            <Hidden smUp>
              <span className="kuswap-font">KUSTACK</span>
            </Hidden>
          </Button>
        </Link>
        {/* <div className={classes.middleNav}>
          <Link className={classes.btn} to={`/${chain}/stake`}>
            Stake KUSGov
          </Link>
        </div> */}
        <Hidden smDown>
          <Box className={classes.flex} sx={{ flexGrow: 1 }}>
            <NavLinks />
          </Box>
        </Hidden>

        <Hidden smDown implementation="css">
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '100%', marginBottom: '1rem', paddingTop: '10px' }}>
              <NavLink to={'/kcc'}>
                <span>All Vaults</span>
              </NavLink>
            </div>
            <div style={{ width: '100%', marginBottom: '1rem', paddingTop: '10px' }}>
              <NavLink to={'/kcc/stake'}>
                <span>Stake KUSGov</span>
              </NavLink>
            </div>
            <div style={{ width: '100%', marginBottom: '1rem', paddingTop: '10px' }}>
              <a href={'https://kuswap.finance'} target="_blank" rel="noopener noreferrer">
                <span>Swap</span>
              </a>
            </div>
            <IconButton onClick={setNightMode} className={classes.icon}>
              {isNightMode ? <Brightness7 /> : <Brightness2 />}
            </IconButton>
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const renderLink = (name, label, icon, classes) => {
  return (
    <a
      href={getLinkUrl(name)}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      style={{ marginLeft: '5px', marginRight: '5px' }}
    >
      <i className={`fas fa-${icon} ${classes.icon}`} />
      <span>{label}</span>
    </a>
  );
};

const LinkSidebar = ({ name, label, icon, classes }) => (
  <div style={{ width: '100%', paddingTop: '10px' }}>{renderLink(name, label, icon, classes)}</div>
);

const getLinkUrl = name => {
  return name === 'buy' ? 'https://kuswap.finance' : `https://kustack.finance/${name}`;
};

export default Header;
