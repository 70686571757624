export const kccPools = [
  {
    id: 'kuswap-kcs-kus',
    name: 'WKCS-KUS LP',
    token: 'WKCS-KUS LP',
    tokenDescription: 'KuSwap',
    tokenAddress: '0x1ee6b0f7302b3c48c5fa89cd0a066309d9ac3584',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'kuV2KUS-KCS',
    earnedTokenAddress: '0xF6741f04C8521751cea7d5634B2457f4D4Cb8a89',
    earnContractAddress: '0xF6741f04C8521751cea7d5634B2457f4D4Cb8a89',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kuswap-wkcs-kus',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KuSwap',
    assets: ['KCS', 'KUS', 'WKCS'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://kuswap.finance/#/add/0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14/0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
    buyTokenUrl:
      'https://kuswap.finance/#/swap?outputCurrency=0x4A81704d8C16d9FB0d7f61B747D0B5a272badf14',
    createdAt: 1648940260,
  },
];
